.stepper-container {
  width: 100%;
  text-align: center;
}

.stepper {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.step {
  width: 30%;
  padding: 10px;
  border-bottom: 3px solid lightgray;
  font-weight: bold;
  color: lightgray;
}

.step.active {
  border-bottom: 3px solid #4caf50;
  color: #4caf50;
}

.content {
  margin: 20px 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: lightgray;
}
