/* Ensure the body and html elements take up the full viewport height */
html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent scrolling */
}

/* Center the hexagon container in the viewport */
.hexagon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden; /* Ensure the SVG does not overflow and cut off */
}

/* Adjust the SVG to fit within the container */
.hexagon-svg {
  transition: all 0.3s ease;
  max-width: 100%; /* Ensure it scales with the container */
  max-height: 100%; /* Ensure it scales with the container */
}

/* Optional: Responsive adjustments for larger screens */
@media (min-width: 1024px) {
  .hexagon-svg {
    width: 30%; /* Adjust as needed */
    height: auto;
  }
}

/* Optional: Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .hexagon-svg {
    width: 100%;
    height: auto;
  }
}
