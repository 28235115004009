.full-blog-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
}

.blogSearch{
  width: 30%;
  border: 1px solid black;
  border-radius: 1rem;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: .5rem;
  padding: .5rem 1rem;
  margin-bottom: .7rem;
}

.blogSearch > input{
  background: transparent;
  border: none;
  color: black;
}

.blogSearch > input::placeholder{
  color: rgb(66, 66, 66);
}

.blogSearch > input:focus{
  outline: none;
}

@media screen and (max-width: 600px) {
  .blogSearch{
    width: 70%;
  }
}