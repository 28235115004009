/* Full viewport height container */
.container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent horizontal overflow */
  align-items: center;
}

/* Styling for publish date */
.publish-date {
  font-size: 12px; /* Adjust font size as needed */
  color: gray; /* Change color for better visibility */
  margin-left: 15px; /* Space between author info and publish date */
}

/* Profile image and author name styling */
.author-info {
  display: flex;
  font-family: source-serif-pro, Georgia, Cambria, 'Times New Roman', Times,
    serif; /* Apply the font */
  font-size: 10px;
  align-items: center; /* Aligns the avatar and text vertically */
  margin: 20px 0; /* Adjust margin as needed */
  justify-content: flex-start; /* Align items to the left */
}

.author-name {
  font-size: 10px;
  color: black;
  margin: 10px;
  font-family: Arial, sans-serif; /* Changed to a simpler font */
}

.button-container {
  margin-left: auto; /* Pushes buttons to the right */
  display: flex; /* Aligns buttons horizontally */
}

/* Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px; /* Adjust based on your navbar height */
  background-color: #333;
  color: #fff; /* Example text color for navbar */
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 1000; /* Ensure it stays above content */
}

/* Footer Styles */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px; /* Adjust based on your footer height */
  background-color: #333;
  color: #fff; /* Text color for footer */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  z-index: 1000; /* Ensure it stays above content */
}
.blog-content h1 {
  color: black; /* Ensure headings are black */
  font-size: 32px;
  font-weight: 900;
  line-height: 28px;
  font-family: 'sohne', 'Helvetica Neue', 'Helvetica', 'Arial'; /* Apply the font */
}

/* Heading Styles */

.blog-content h2,
.blog-content h3,
.blog-content h4,
.blog-content h5,
.blog-content h6 {
  font-weight: 800;
  line-height: 48px;
  color: black; /* Ensure headings are black */
  font-size: 36px;
  font-family: 'sohne', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; /* Apply the font */
}

/* Content Area Styles */
.blog-content {
  margin: 0 auto; /* Center horizontally */
  line-height: 28px;
  letter-spacing: -0.003em;
  overflow: clip;
  margin-top: 60px; /* Space for the navbar */
  margin-bottom: 60px; /* Space for the footer */
  padding: 16px; /* Optional padding */
  overflow-y: scroll; /* Allow vertical scrolling */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  font-family: source-serif-pro, Georgia, Cambria, 'Times New Roman', Times,
    serif; /* Apply the font */
  font-size: 18px;
  color: black; /* Text color */
  height: calc(
    100vh - 140px
  ); /* Adjust height to fit within viewport minus navbar and footer */
  width: 50%; /* Adjust width */
  max-width: 1200px; /* Optional max width to limit content on larger screens */
  text-rendering: optimizeLegibility;
}

.blog-content::-webkit-scrollbar {
  display: none;
}

/* Image Styles */
.blog-content img {
  width: 100%;

  max-width: 100%; /* Ensure images don’t overflow */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove extra space below images */
  margin: 0 auto; /* Center images */
  object-fit: contain; /* Ensure images are fully visible */
}

/* Style for centering loading text */
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 120px); /* Full height minus navbar and footer */
  width: 100%;
  font-family: 'Black Ops One', sans-serif; /* Apply the font */
  color: black; /* Text color */
  font-size: 24px; /* Adjust font size */
}
.like-count{
  font-size: 12px; /* Adjust font size as needed */
  color: gray; /* Change color for better visibility */

}

@media (max-width: 768px) {
  .blog-content {
    width: 90%; /* Adjust width for mobile devices */
  }
  .blog-content img {
    width: 100%;
    max-width: 100%; /* Adjust the width for mobile devices */
  }

  .loading-container {
    font-size: 18px; /* Adjust font size for mobile devices */
  }
  .author-info {
    align-items: center; /* Center items horizontally on small screens */
    text-align: center; /* Center align text */
  }

  .sharebutton,
  .likebutton {
    width: 100%; /* Make buttons full-width */
    margin: 2px 0; /* Adjust margin for mobile */
  }
}
