/* General layout for footer and stepper */
.content {
  padding: 20px;
  text-align: center;
}

/* Footer styling */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-content p {
  margin: 0;
  font-size: 14px;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.footer-links li {
  display: inline;
  margin: 0 10px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Make sure stepper and footer don't overlap */
.content,
.footer {
  margin-bottom: 30px;
}
