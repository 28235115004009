.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 400px; /* Fixed width for modal */
  max-width: 90vw; /* Responsive max-width */
  max-height: 90vh; /* Responsive max-height */
  overflow: hidden; /* Prevent horizontal overflow */
  font-family: 'Inter', sans-serif;
}

@media (max-width: 768px) {
  .modal-content {
    width: 300px; /* Adjust width for mobile */
  }
}

.modal-heading {
  color: black;
  text-align: left;
  font-weight: 700;
}

.category-buttons {
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
}

.category-buttons button {
  margin-right: 10px;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  color: black;
  font-family: 'Inter', sans-serif; /* Consistent font family */
  font-weight: 700; /* Consistent font weight */
}

.category-buttons button.active {
  background-color: black;
  color: white;
}

.category-buttons button:hover {
  background-color: black;
  color: white;
}

.save-button {
  padding: 10px 20px; /* Same padding for save button */
  border: none;
  border-radius: 20px; /* Same border-radius */
  cursor: pointer;
  font-family: 'Inter', sans-serif; /* Ensures consistent font family */
  font-weight: 700; /* Ensures consistent font weight */
  background-color: black; /* Set background color to black */
  color: white; /* Set text color to white */
  transition: background-color 0.3s; /* Add transition for hover effect */
}

.save-button:hover {
  background-color: #444; /* Darker hover state */
}

.confirmation-message {
  margin-top: 15px;
  color: #00796b;
  font-family: 'Inter', sans-serif;
}

.hashtag-input {
  margin: 10px 0;
  width: 100%; /* Full width to avoid overflow */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  box-sizing: border-box; /* Include padding in width */
}

.popular-hashtags {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; /* Allow hashtags to wrap to new lines */
}

.popular-hashtags .hashtag-button {
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 20px;
  background-color: transparent;
  color: black;
  font-family: 'Inter', sans-serif; /* Consistent font family */
  font-weight: 700; /* Consistent font weight */
  cursor: pointer;
}

.popular-hashtags .hashtag-button:hover {
  background-color: black;
  color: white;
}
