@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: linear-gradient(to right, #a6a6a6, #ffffff);
  height: 100%; /* Full viewport height */
}

.spline-container {
  display: flex;
  width: 100%;
  height: 70vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Center horizontally */
}

.centered-text {
  /* Optional styling for the centered text */
  font-size: 24px;
  text-align: center;
}

.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center vertically */
  font-size: 24px; /* Adjust the font size if needed */
}

.spline-container canvas {
  width: 100%;

  object-fit: cover;
}

.overlay-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  z-index: 1;
  text-align: center;
  font-family: 'Black Ops One';
  font-size: 25px;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.overlay-text p {
  margin: 5px 0 0;
  font-size: 1em;
  font-weight: 400;
}

.container-upper,
.container-upper-extra,
.container-middle,
.container-lower {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  white-space: nowrap; /* Ensure text does not wrap */
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button{
  background: black;
  border-radius: 3rem;
  color: white;
  padding: 1rem 2rem;
  text-decoration: none;
  font-family: 'Black Ops One', sans-serif;
}