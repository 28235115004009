/* Add this at the top of your CSS file */

body,
h1,
h2,
h3,
p,
.newText,
.question,
.noOption,
.yesOption,
.infoText,
.buttons button {
  user-select: none; /* Prevents text selection */
}

/* Existing CSS */

.app {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height to ensure vertical centering */
}

.app > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width to ensure horizontal centering */
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

h1 {
  font-family: 'Damion', cursive;
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
}

h2 {
  color: #fff;
}

/* .swipe:hover {
  cursor: grab;
}

.swipe:active{
  cursor: grabbing;
} */

.cardContainer {
  width: 90vw;
  max-width: 260px;
  height: 300px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.card {
  position: relative;
  background: linear-gradient(
    to right,
    #a6a6a6,
    #ffffff
  ); /* Gradient background */
  width: 80vw;
  max-width: 260px;
  margin-top: 25px;
  height: 350px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.newText {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: 'Black Ops One';
}

.question {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%; /* Adjust this value to position the question higher */
  margin-top: -5%; /* Adjust this value to position the question higher */
  text-align: center;
  font-size: 1.5rem;
  font-family: 'Black Ops One';
}

.final-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%; /* Adjust this value to position the question higher */
  margin-top: -5%; /* Adjust this value to position the question higher */
  text-align: center;
  font-size: 25px;
  font-family: 'Black Ops One';
}

.centered-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Black Ops One';
  text-align: center;
}

.sign-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.question p {
  margin: 0;
}

/* .swipe:last-of-type {
} */

.horizontalLine {
  width: 100%;
  height: 5px;
  background-color: #000;
  margin: 20px 0; /* Adjust margin as needed */
}

.verticalLine {
  width: 5px;
  height: calc(
    100% - 76%
  ); /* Adjust this value based on question height and margin */
  background-color: #000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.answerOptions {
  position: absolute;
  bottom: 10px;
  left: 10px;
  /* z-index: 100; */
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
}

.noOption,
.yesOption {
  /* background-color: rgba(255, 255, 255, 0.8); */
  padding: 13px 20px;
  font-family: 'Black Ops One';
  font-size: 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.noOption {
  /* background-color: red; */
  color: black;
}

.yesOption {
  /* background-color: #4caf50; */
  color: black;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 625px) {
  .buttons {
    flex-direction: column;
  }

  .yesOption,
  .noOption,
  .question{
    font-size: 1.2rem;
  }
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  margin: 10px;
  font-weight: bolder;
  width: 160px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Media query for larger screens like laptops */
@media (min-width: 1024px) {
  .cardContainer {
    height: 350px;
  }

  .card {
    transform: translateY(20px); /* Adjust the 20px value as needed */
    width: 60vw;
    max-width: 500px;
    height: 300px;
  }
}

@media screen and (min-width: 1440px) {
  .card{
    height: 360px;
  }
}
